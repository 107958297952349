<template>
  <div>
    <!-- S:Bread crumbs -->
    <v-row>
      <v-col sm="12" md="12" lg="12" class="pa-10 pb-2">
        <v-card elevation="1" class="containerbox">
          <breadcrumbComp class="hidden-sm-and-down" :mainPage="mainPage" :subPage="subPage" :backSlash="backSlash"
            :pageUrl="pageUrl" :refresh="refresh" :resetFunction="resetFunction" :showAdd="showAdd"
            @addFav="addFav()" />
          <!--  Bread Crumb for mobile -->
          <breadcrumbComp class="hidden-md-and-up" :subPage="subPage" :pageUrl="pageUrl" :showAdd="showAdd"
            @addFav="addFav()" :refresh="refresh" :resetFunction="resetFunction" />
          <!-- E:Breadcrumbs -->
          <!-- Search Card -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="pa-4">
              <v-card outlined color="transparent" class="pa-0 ma-0 containerbox pb-5">
                <v-card-text class="pb-0 pt-0">
                  <v-form ref="companyForm" class="pa-2 pr-5" v-model="isFormValid" lazy-validation>
                    <v-row class="pt-5">
                      <!-- For Company ID -->
                      <v-col cols="12" sm="12" md="12" lg="11" class="pl-5 pr-1 py-0">
                        <v-text-field color="primarytext" v-model="search" label="Search" outlined clearable dense
                          class="ma-0" persistent-placeholder>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="1" class="pl-1 pr-5 py-0">
                        <!-- For Toggle between Active and inactive -->
                        <v-switch class="my-2 mx-0 d-inline-block float-right" v-model="isActive"
                          :label="isActive ? 'Active' : 'Inactive'" color="primarytext" hide-details inset></v-switch>
                      </v-col>

                    </v-row>
                    <v-row>
                      <!-- Project-->
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
                        <v-autocomplete persistent-placeholder outlined v-model="projectId" dense :items="projectList"
                          label="Project" item-text="Project" item-value="ProjKey" @change=onChangeProject(projectId)
                          required>
                        </v-autocomplete>
                      </v-col>
                      <!-- Business -->
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
                        <v-autocomplete :disabled="!projectId" persistent-placeholder outlined attach
                          v-model="businessId" dense :items="businessList" class="ma-0 pa-0" label="Business"
                          item-text="business" multiple item-value="bu_id" required>
                        </v-autocomplete>
                      </v-col>
                      <!-- Company Type-->
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-4 py-0">
                        <v-autocomplete color="primarytext" outlined v-model="companyType" dense
                          :items="companyTypeList" label="Company Type" item-text="companyType" item-value="typeId"
                          required @change="onSelectCompany(companyType)" multiple small-chips
                          class="rounded-5 pa-0 border-color" persistent-placeholder>
                          <template #selection="{ item }">
                            <v-chip class="ma-1 pa-1" color="primary" label>{{
                              item.companyType
                            }}</v-chip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <!--  Serarch Button and cancel -->
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0"></v-col>
                      <v-col cols="12" sm="12" md="12" lg="2" class="px-5 py-0">
                        <v-btn color="primarytext" outlined class="ma-2 btn-style" elevation="1" @click="getPageData(1)"
                          :disabled="!isFormValid">
                          <v-icon dark left> mdi-magnify </v-icon>Search Company
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="2" class="px-5 py-0">
                        <v-btn color="secondary" outlined dense class="ma-2 btn-style" elevation="1"
                          @click="onSearchCancel()">
                          <v-icon dark left> mdi-magnify-close </v-icon>Clear Search
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0"></v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- Table -->
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="pt-5 pa-10">
        <v-card outlined class="containerbox">
          <v-card-text class="pb-0 pt-0">
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="9" class="px-5 pt-10">
                <span color="primarytext">
                  No.of Records: {{ totalRecords }}
                </span>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3" class="px-5 ma-0 pt-6">
                <!-- Button to add user -->
                <v-btn class="my-2 mx-0 btn-style float-right px-2 success" elevation="1" outlined color="cWhite"
                  @click="newCompanyClicked()" v-if="actionSetter('AddCompany')">
                  <v-icon dark left> mdi-plus </v-icon>New Company
                </v-btn>
                <!-- Company add Comp -->
                <export-excel  class="btn btn-default" :fetch="exportExcel" :fields="json_fields" worksheet="Sheet1"
                  :name="excelName" :before-generate="startDownload" :before-finish="finishDownload"
                  v-if="actionSetter('ExportCompany') && !exportDisable">
                  <!-- Button to add user -->
                  <v-btn  class="ma-2 btn-style float-right" elevation="1" outlined
                    color="secondary">
                    <v-icon dark left> mdi-file-export </v-icon>Export
                  </v-btn>
                </export-excel>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12" class="pa-0 px-5 pb-5">
                <v-data-table :headers="headers" :items="companyList" item-key="comp_id" hide-default-footer>
                  <template v-slot:item.address="{ item }">
                    <v-text>{{ item.address1 }} </v-text>
                    {{ item.address2 }}<br />
                    {{ item.city }}<br />{{ item.state }}<br />
                    {{ item.country }}
                  </template>
                  <template v-slot:item.active="{ item }">
                    <span class="activeCheck" v-if="item.active == '1'">Active</span>
                    <span class="inactive" v-else>Inactive</span>
                  </template>

                  <template v-slot:item.comp_type="{ item }">
                    <ul class="unordered-class d-flex align-center  flex-wrap">
                      <li v-for="(ele) in item.comp_type" :key="ele" class="mt-2 list-style ">
                        <span style="width: 50px"
                          v-bind:class="($vuetify.theme.dark) ? 'chipData-dark mt-2 mb-0 pa-1 f-12' : 'chipData mt-2 mb-0 pa-1 f-12'"
                          v-if="ele" >  
                          {{ ele }}
                        </span>
                      </li>
                    </ul>
                  </template>

                  <template v-slot:item.custno="{ item }">
                    <a @click="companyDetails(item)"
                      v-bind:class="($vuetify.theme.dark) ? 'anchorDark' : 'anchorlight'">
                      {{ item.custno }}
                    </a>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="companyDetails(item)">
                      mdi-eye
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="4" md="4" sm="4" class="pt-0 px-5"></v-col>
              <v-col cols="12" lg="5" md="5" sm="5" class="pt-0 pb-6 px-5">
                <v-pagination v-if="pageCount > 0" v-model="page" :length="pageCount" @input="pageNumber(page)">
                </v-pagination>
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="3" class="pt-2 px-5"> </v-col>
            </v-row>
            <companyComp v-if="compCalled" :name="name" :compCalled="compCalled" @hide="close()"
              @companyUpdate="updatedCompany()" :companyId="selectedCompanyId" :userId="userId"
              :actionList="actionList" />
            <!-- Pagination-->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <exportLimit v-show="exportDialog" :exportDialog="exportDialog" :totalRecords="totalRecords"
      @exportData="exportData()" />

  </div>
</template>

<script>
import Vue from 'vue';
import excel from 'vue-excel-export';
import breadcrumbComp from '../common/breadcrumb-comp.vue';
import EncryptUtility from '../utility/js/encrypt-utility';
import AddFavourite from "../utility/js/addFav"
import companyComp from './app-company-comp.vue';
import Utility from '../../shared/utility';
import exportLimit from '../common/export-limit.vue';
import commonApiService from "../common/js/common-api-service";
Vue.use(excel);
export default {
  data: () => ({
    name: 'newCompany',
    mainPage: '',
    subPage: '',
    compCalled: false,
    showAdd: false,
    favouriteList: [],
    isFormValid: false,
    exportDisable: true,
    projectId: "",
    companyType: [],
    companyTypeList: [
      { companyType: "OEM", typeId: 1, disabled: false },
      { companyType: "ODM", typeId: 2, disabled: false },
      { companyType: "Contact", typeId: 3, disabled: false },
      { companyType: "Bill-To", typeId: 4, disabled: false },
      { companyType: "Project Owner", typeId: 5, disabled: false },
      { companyType: "Warehouse Location", typeId: 6, disabled: false },
      { companyType: "Depot", typeId: 7, disabled: false },
      {
        companyType: "Forward Stocking Location (FSL)",
        typeId: 8,
        disabled: false,
      },
      { companyType: "Warehouse Owner", typeId: 9, disabled: false },
      { companyType: "Return-To Location", typeId: 10, disabled: false },
    ],
    exportDialog: false,
    export50kData: false,
    userId: EncryptUtility.localStorageDecrypt('userID'),
    companyList: [],
    exportCompanyList: [],
    maxRulesInt: [
      (v) => (v || '').length <= 20 || 'Max Length of 20 character',
    ],
    maxCustRule: [
      (v) => (v || '').length <= 50 || 'Max Length of 50 character',
    ],
    maxNameRule: [
      (v) => (v || '').length <= 100 || 'Max Length of 100 character',
    ],
    json_fields: {
      'Company Code': 'custno',
      Company: 'Company',
      Address: 'Address',
      'Company Type': {
        field: 'comp_type',
        callback: (companytypes) => {
          companytypes = companytypes.replaceAll(";", " ")
          return companytypes.trimEnd()
        }
      },
      Status: 'active',
    },
    json_meta: [
      [
        {
          key: 'charset',
          value: 'utf-8',
        },
      ],
    ],
    refresh: true,
    isActive: true,
    projectList: [],
    businessId: [],
    businessList: [],
    excelName: '',
    zip: '',
    state: '',
    pageCount: 0,
    companyName: '',
    backSlash: true,
    search: '',
    selectedCompanyId: '',
    compcalled: false,
    active: false,
    company: '',
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    page: 1,
    address1: '',
    address2: '',
    city: '',
    selectedState: '',
    postal: '',
    selectedCountry: '',
    phone1: '',
    phone2: '',
    cell: '',
    fax: '',
    projectOwner: '',
    odm: '',
    oem: '',
    wh_Owner: '',
    projectArray: [],
    headers: [
      {
        text: 'Company Code',
        align: 'start',
        value: 'custno',
        class: 'primary customwhite--text',
        width: "10%",
      },
      { text: 'Company', value: 'Company', class: 'primary customwhite--text', width: "15%", },
      { text: 'Address', value: 'address', class: 'primary customwhite--text', width: "25%", },
      { text: 'Company Type', value: 'comp_type', class: 'primary customwhite--text', width: "24%", },
      { text: 'Status', value: 'active', class: 'primary customwhite--text', width: "13%", },
      {
        text: 'Actions',
        value: 'actions',
        class: 'primary customwhite--text',
        sortable: false,
        width: "13%",
      },
    ],
    pageUrl: '',
    wh_Local: '',
    exportFlag: true,
    contact: '',
    actionList: [],
    route: '',
    bill2: '',
    depot: '',
    fsl: '',
    selectedProject: '',
    custno: '',
    totalRecords: 0,
    backSlashParent: true,
  }),
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route)
    this.actionList = data.actionList
    this.mainPage = data.mainPage
    this.favouriteList = data.favouriteList
    this.pageUrl = data.pageUrl
    this.subPage = data.subPage
    this.showAdd = data.showAdd
  },
  async mounted() {
    let projectData = await commonApiService.getProject("get", this.userId);
    if (projectData?.ProjectInfo) this.projectList = projectData.ProjectInfo;

  },
  methods: {

    async getBussinessData(businessObject) {
      let businessData = await commonApiService.postBusinessData("post", businessObject);
      this.businessList = businessData.map((x) => ({
        bu_id: x.Bu_id,
        business: x.Business,
        bu_payterm: x.pterms,
      }));
    },

    onChangeProject(projectId) {
      this.projectArray = []
      this.projectArray.push(projectId)
      let businessObj = {
        UserId: this.userId,
        ProjKey: projectId,
        BuKey: 0, // Do not Delete
        IncludeEmptyBU: 0, // Do not Delete
        WithinSameLocation: 1, // Do not Delete
        loca_key: 0,
      };
      this.getBussinessData(businessObj);

    },
    // TO get PageNumber
    pageNumber(page) {
      this.getPageData(page);
    },
    // to set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action,
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
    //  on Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route)
    },

    //Cancel search Paramter
    onSearchCancel() {
      this.search = '';
      this.projectId = "";
      this.projectArray = "";
      this.isActive = true;
      this.businessList = []
      this.businessId = []
      this.page = 1;
      this.companyList = [];
      this.totalRecords = 0;
      this.pageCount = 0;
      this.exportDisable = true;
      this.companyType = [];
    },
    // to close the diaglog box
    close() {
      this.name = '';
      this.compCalled = false;
      this.custno = '';
      this.firstName = '';
      this.lastName = '';
      this.title = '';
      this.email = '';
      this.address1 = '';
      this.address2 = '';
      this.city = '';
      this.selectedState = '';
      this.postal = '';
      this.selectedCountry = '';
      this.phone1 = '';
      this.phone2 = '';
      this.cell = '';
      this.fax = '';
      this.projectOwner = false;
      this.odm = false;
      this.oem = false;
      this.wh_Owner = false;
      this.wh_Local = false;
      this.contact = false;
      this.bill2 = false;
      this.depot = false;
      this.fsl = false;
      this.active = false;
      this.selectedProject = '';
      this.company = '';
    },
    //TO pass Values from table to Edit comp
    companyDetails(item) {
      this.compCalled = true;
      this.name = 'editCompany';
      this.selectedCompanyId = item.comp_id;
      this.active = item.active;
      this.company = item.Company;
      this.firstName = item.first;
      this.lastName = item.last;
      this.title = item.title;
      this.email = item.email;
      this.address1 = item.address1;
      this.address2 = item.address2;
      this.city = item.city;
      this.selectedState = item.state;
      this.postal = item.zip;
      this.selectedCountry = item.country;
      this.phone1 = item.phone1;
      this.phone2 = item.phone2;
      this.cell = item.cell;
      this.fax = item.fax;
      this.projectOwner = item.project_owner;
      this.odm = item.odm;
      this.oem = item.oem;
      this.wh_Owner = item.wh_owner;
      this.wh_Local = item.wh_loca;
      this.contact = item.contact;
      this.bill2 = item.bill2;
      this.depot = item.depot;
      this.fsl = item.fsl;
      this.selectedProject = item.proj_key;
      this.custno = item.custno;
    },
    async exportData() {
      this.exportDialog  =false
    },
    


    // to export the Execel file
    async exportExcel() {
      this.exportDialog = false
      if (this.totalRecords <= 15000 ) {
        let searchObject = {
          search_key: this.search,
          proj_keys: this.projectArray,
          bu_keys: this.businessId,
          strActive: this.isActive ? '1' : '0',
          PageNumber: 1,
          UserId: parseInt(this.userId),
          Pass: 2,
        };
        let searchJson = { "json": JSON.stringify(searchObject) }
        this.excelName =
          'Company_List_' +
          new Date()
            .toISOString()
            .replace('T', '_')
            .replace('Z', '')
            .replace('.', '')
            .replaceAll('-', '_') +
          '.xls';
        const response = await this.axios.post('cp/company_list', searchJson);
        let responseData = JSON.parse(response.data.body.message).Data;
        responseData.forEach(obj => {
          obj.Address = obj.address1 + " " + obj.address2 + " " + obj.city + " " + obj.state + " " + obj.country + " " + obj.zip
        })
        this.export50kData = false
        return responseData;
      }
      else {
        this.exportDialog = true
        this.finishDownload()
        return true
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
    },
    //File Name for Export
    fileName() {
      if (this.totalRecords <= 15000 || this.export50kData)
        this.exportFlag = true
      this.excelName =
        'companyList_' +
        new Date()
          .toISOString()
          .replace('T', '_')
          .replace('Z', '')
          .replace('.', '')
          .replaceAll('-', '_') +
        '.xls';
    },
    //Load Company Grid information
    getPageData(pageNum) {
      this.exportDialog = false
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      let companTypeList = []
      this.companyType?.forEach((element) => {
        switch (element) {
          case 1:
            companTypeList.push("oem")
            break;
          case 2:
            companTypeList.push("odm")
            break;
          case 3:
            companTypeList.push("contact")
            break;
          case 4:
            companTypeList.push("bill2")
            break;
          case 5:
            companTypeList.push("project_owner")
            break;
          case 6:
            companTypeList.push("wh_Local")
            break;
          case 7:
            companTypeList.push("depot")
            break;
          case 8:
            companTypeList.push("fsl")
            break;
          case 9:
            companTypeList.push("wh_owner")
            break;
          case 10:
            companTypeList.push("so_retloc")
            break;
        }
      });
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchObject = {
        search_key: this.search,
        proj_keys: this.projectArray,
        bu_keys: this.businessId,
        strActive: this.isActive ? '1' : '0',
        PageNumber: pageNum,
        UserId: parseInt(this.userId),
        Pass: 1,
        comp_types: companTypeList
      };
      let searchJson = { "json": JSON.stringify(searchObject) }
      this.axios
        .post('cp/company_list', searchJson)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            this.pageCount = responseData.TotalPages;
            this.totalRecords = responseData.TotalRecords;
            this.companyList = responseData.Data;
            this.companyList.forEach((element) => {
              element.comp_type = element.comp_type.split(";")

            });
            this.exportDisable = false;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          this.companyList = [];
          this.exportDisable = true;
          this.totalRecords = 0;
          this.pageCount = 0;
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Add new company button event
    newCompanyClicked() {
      this.compCalled = true;
      this.name = 'newCompany';
    },
    //When new business is added or existing business updated
    updatedCompany() {
      this.selectedProject = '';
      this.page = 1;
      this.companyList = [];
      this.totalRecords = 0;
      this.pageCount = 0;
      this.name = '';
      this.compCalled = false;
      this.getPageData(1);
    },
    resetFunction() {
      this.search = '';
      this.companyName = '';
      this.state = '';
      this.zip = '';
      this.selectedProject = '';
      this.isActive = true;
      this.companyList = [];
      this.totalRecords = 0;
      this.pageCount = 0;
      this.page = 1;
      this.projectId = "";
      this.businessId = "";
      this.companyType = "";
      this.exportDisable = true;
      this.selectedCompanyId = '';
      this.companyType = [];
    },
    // to enable or disable company Type
    onSelectCompany(companyType) {
      if (companyType.includes(7)) {
        this.companyTypeList[7].disabled = true;
      } else {
        this.companyTypeList[7].disabled = false;
      }
      if (companyType.includes(8)) {
        this.companyTypeList[6].disabled = true;
      } else {
        this.companyTypeList[6].disabled = false;
      }
    },
  },
  components: {
    breadcrumbComp,
    companyComp,
    exportLimit,
  },
};
</script>

<style scoped>


.chipData {
  background-color: var(--v-primarytext-base);
  border-radius: 5px;
  padding: 2px;
  color: white;

}

.chipData-dark {
  background: var(--v-pTableHeader-base);
  border-radius: 5px;
  padding: 2px;
  color: white;

}


.list-style {
  margin: auto;
  width: auto !important;
}

.unordered-class {
  columns: 2 !important;
  list-style-type: none !important;
  column-gap: 0 !important;
  padding: 10px 0 !important;
  margin: auto;
}
</style>
